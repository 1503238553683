import _get from "lodash/get";
import _castArray from "lodash/castArray";
import moment from "moment";

import { Base, DistanceMatrixResponse } from "@/models";
import { toCamelCase, omitDeep } from "@/utilities";
import {
  Booking,
  Photo,
  Address,
  RateCard,
  Location,
  Schedule,
  Customer,
} from "@/models";
import defaultschedules from "@/store/inspectors/json/schedules.json";
import { InspectorTravelTime } from "./diary/inspectortraveltime.model";

export class Inspector extends Base {
  public id: string;
  public itype: string;
  public name: string;
  public address: Address;
  public mobile: string;
  public email: string;
  public googlecalendaremail: string;
  public transport: string;
  public region: string;
  public appointmentcolour: string;
  public picture: Photo[];
  public ratecards: RateCard[];
  public locations: Location[];
  public schedules: Schedule[];
  public excludedclients: Customer[];
  public preferredclients: Customer[];
  public bookings: Booking[];
  public inspectors: string[];
  public inactive: boolean;

  public fromDurationText: string = "";
  public fromDurationSecs: number = -1;
  public topTo = false;

  public createdAt: string;
  public updatedAt: string;
  public signature: string;

  public distancematrix: DistanceMatrixResponse = new DistanceMatrixResponse();
  public traveltime: InspectorTravelTime = new InspectorTravelTime();
  public calculatingtraveltime: boolean = false;

  /**
   * Constructor initialises the variables from the class Inspector
   * @param data - the data to construct a type with all properties of <Inspector>
   */
  public constructor(data?: Partial<Inspector>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, "id", "");
    this.itype = _get(data, "itype", "");
    this.name = _get(data, "name", "");
    this.address = new Address(_get(data, "address"));
    this.mobile = _get(data, "mobile", "");
    this.email = _get(data, "email", "");
    this.googlecalendaremail = _get(data, "googlecalendaremail", "");
    this.transport = _get(data, "transport", "");
    this.region = _get(data, "region", "");
    this.appointmentcolour = _get(data, "appointmentcolour", "");
    this.picture = _get(data, "picture", []);
    this.ratecards = _castArray(_get(data, "ratecards", [])).map(
      (r: Partial<RateCard>) => new RateCard(r)
    );
    this.locations = _castArray(_get(data, "locations", [])).map(
      (r: Partial<Location>) => new Location(r)
    );
    this.schedules = _castArray(_get(data, "schedules", [])).map(
      (r: Partial<Schedule>) => new Schedule(r)
    );
    this.excludedclients = _castArray(_get(data, "excludedclients", [])).map(
      (r: Partial<Customer>) => new Customer(r)
    );
    this.preferredclients = _castArray(_get(data, "preferredclients", [])).map(
      (r: Partial<Customer>) => new Customer(r)
    );
    this.bookings = _castArray(_get(data, "bookings", [])).map(
      (b: Partial<Booking>) => new Booking(b)
    );
    this.inspectors = _castArray(_get(data, "inspectors", []));
    this.inactive = _get(data, "inactive", false);

    this.createdAt = _get(data, "createdAt", "");
    this.updatedAt = _get(data, "updatedAt", "");
    this.signature = _get(data, "signature", "");

    let cutoffDate = moment("2024-03-02").utc().startOf("day");
    let lastUpdatedDate = moment();
    if (this.updatedAt) {
      lastUpdatedDate = moment(this.updatedAt);
    }
    if (lastUpdatedDate.isBefore(cutoffDate)) {
      this.schedules = defaultschedules.schedules.map(
        (s: any) => new Schedule(s)
      );
    }
  }

  // accessors for scheduler
  get text() {
    return this.name;
  }

  get travelmode() {
    var mode = "transit";
    if (this.transport) {
      switch (this.transport) {
        case "Car":
          mode = "driving";
          break;
        case "Train":
          mode = "transit";
          break;
        case "Bike":
          mode = "bicycling";
          break;
        case "Walking":
          mode = "walking";
          break;
      }
    }
    return mode;
  }

  public getAreacount(postcode: string): number {
    let count = 0;
    if (postcode?.trim().length > 0) {
      postcode = postcode.replace(/ /g, "");
      let removefromback = 3;
      if (postcode.length > 6) removefromback = 4;
      let areacode = postcode.substring(0, postcode.length - removefromback);

      if (this.bookings?.length) {
        this.bookings.forEach((b: Booking) => {
          if (b?.address?.postcode) {
            let bookingpostcode = b?.address?.postcode.replace(/ /g, "");
            let code = bookingpostcode.substring(0, postcode.length - 3);
            if (code === areacode) count += 1;
          }
        });
      }
    }
    return count;
  }

  public getCloseAreacount(postcode: string): number {
    postcode = postcode.replace(/ /g, "");
    let areacode = postcode.substring(0, postcode.length - 4);
    let count = 0;
    if (this.bookings?.length) {
      this.bookings.forEach((b: Booking) => {
        if (b?.address?.postcode) {
          let bookingpostcode = b?.address?.postcode.replace(/ /g, "");
          let code = bookingpostcode.substring(0, postcode.length - 4);
          if (code === areacode) count += 1;
        }
      });
    }
    return count;
  }

  get fromDurationMins() {
    let value: string = "0";
    if (this.fromDurationSecs) {
      value = `${Math.round(this.fromDurationSecs / 60)}`;
    }
    return value;
  }
  public clearTravelTimes() {
    this.fromDurationText = "";
    this.fromDurationSecs = -1;
    this.topTo = false;
  }

  get startpostcodes(): string[] {
    var postcode: string[] = [];
    if (this.address?.postcode) {
      postcode.push(this.address.postcode);
    }
    return postcode;
  }

  get endpostcodes(): string[] {
    var postcode: string[] = [];
    if (this.address?.postcode) {
      postcode.push(this.address.postcode);
    }
    return postcode;
  }

  /**
   * Convert class instance back into a plain old JSON object
   * and recursively remove all uuids we've add for Vue.
   *
   * @return {Object}
   */
  public toObject() {
    return omitDeep({ ...this }, ["bookings"]);
  }
}
