<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="input-group">
      <label for="report-search" class="sr-only">Search</label>
      <input
        id="report-search"
        type="search"
        class="form-control searchbar"
        v-model="searchvalue"
        ref="searchinput"
        :placeholder="placeholder"
        autocomplete="off"
      />
      <span class="input-group-btn ml-2">
        <button type="submit" class="btn btn-outline-primary custom-white-button">
          <i class="fas fa-search"></i>
        </button>
      </span>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject, defineProps, defineEmits,watch } from 'vue';
import Ably from "ably";
import { useRoute } from 'vue-router';
const realtime: Ably.Realtime = inject('realtime');
const actProperty: any = inject('actProperty');
const route = useRoute();

const props = defineProps<{
  placeholder: string;
  showserachresulttable: boolean;
}>();

const searchvalue = ref('');
const searchinput = ref(null);

const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();
const emits = defineEmits(['searchsubmit']);

const onSubmit = () => {
  emits('searchsubmit', searchvalue.value);
  channel.publish('appointmentSearch', { deviceid: deviceid, searchtext: searchvalue.value });
};

watch(() => route.params.id, async (id: string) => {
  searchinput.value.focus();
});

onMounted(() => {
  channel.subscribe('appointmentSearch', (message: any) => {
    if (props.showserachresulttable && message?.data) {
      if (message.data.deviceid !== deviceid) return;
      if (message.data.searchtext && searchvalue.value !== message.data.searchtext) {
        searchvalue.value = message.data.searchtext;
        emits('searchsubmit', searchvalue.value);
      }
    }
  });
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.searchbar {
  border-radius: 2px;
}

.custom-white-button {
  color: #fff;            
  border-color: #fff;    

}
</style>
