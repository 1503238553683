<template>
  <div class="circle-icon" v-if="leftNumber > 0 || rightNumber > 0">
    <svg viewBox="0 0 45 45" class="circle-svg">
      <circle cx="22.5" cy="22.5" r="20" stroke-width="2" class="circle-border" />
      <!-- Left Half Circle -->
      <path v-if="half == 'left' || half == 'both'" 
        fill="#253746" stroke="none" stroke-width="0"
        d="M22.5,2.5 A20,20 0 0,0 22.5,42.5 L22.5,22.5 Z"/>
      <!-- Right Half Circle -->
      <path v-if="half == 'right' || half == 'both'" 
        fill="#253746" stroke="none" stroke-width="0"
        d="M22.5,2.5 A20,20 0 0,1 22.5,42.5 L22.5,22.5 Z" />
      <!-- Vertical Line -->
      <line x1="22.5" y1="5" x2="22.5" y2="40" stroke="#868686" stroke-width="2" />
    </svg>
    <span class="time-text left" v-if="leftNumber > 0" v-tooltip="{ content: leftTitleToolTip }">{{ leftNumber }}</span>
    <span class="time-text right" v-if="rightNumber > 0" v-tooltip="{ content: rightTitleToolTip}">{{ rightNumber }}</span>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, computed, PropType } from 'vue';

// Defining props using defineProps with type annotations
const props = defineProps({
  half: {type: String as PropType<string>, default: ''},
  bookingTime: {type: String as PropType<string>, default: ''},
  leftTitle: {type: String as PropType<string>, default: ''},
  leftNumber: {type: Number as PropType<number>, default: 0},
  rightTitle: {type: String as PropType<string>, default: ''},
  rightNumber: {type: Number as PropType<number>, default: 0},
});

const leftTitleToolTip = computed(() => {
  let tooltip = '';
  if(props.leftTitle && props.bookingTime) {
    tooltip = `${props.leftTitle} <span class='tooltip-bookingtime'><b>${props.bookingTime}</b></span>`;
  }
  else if(props.leftTitle) {
    tooltip = `${props.leftTitle}`;
  }
  return tooltip;
});

const rightTitleToolTip = computed(() => {
  let tooltip = '';
  if(props.rightTitle && props.bookingTime) {
    tooltip = `<span class='tooltip-bookingtime'><b>${props.bookingTime}</b></span> ${props.rightTitle}`;
  }
  else if(props.rightTitle) {
    tooltip = `${props.rightTitle}`;
  }
  return tooltip;
});
</script>

<style scoped>
.circle-icon {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
}

.circle-svg {
  width: 100%;
  height: 100%;
}

.circle-border {
  fill: none;
  stroke: #303030; /* Background circle color */
}

.circle-half {
  fill: #868686; /* Color for the greyed-out half */
}

.time-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
  color: #eadb40;
}

.left {
  left: 10%;
}

.right {
  right: 10%;
}
</style>

<style>
.tooltip-bookingtime {
  padding: 3px;
  background: #253746;
  color: #eadb40;
}
.v-popper__popper
.v-popper__wrapper
.v-popper__inner {
	background: #ffffff !important;
  color: #000000 !important;
  border: 1px #b5b5b5 solid !important;
}
.v-popper__popper
.v-popper__wrapper
.v-popper__arrow-outer {
  border-color: #b5b5b5;
}
.v-popper__popper
.v-popper__wrapper
.v-popper__arrow-inner {
  visibility: visible;
  border-color: #ffffff;
}
</style>