<template>
  <div class="home-icon" v-if="rating > 0" v-tooltip="{ content: title }" @click.stop="onClickQuestionMark">
    <svg class="home-svg" viewBox="0 0 576 512">
      <path :fill="fillColor" stroke-width="2" class="home-border" d="M570.7 236.3 512 184.4V48a16 16 0 0 0 -16-16H432a16 16 0 0 0 -16 16V99.7L314.8 10.3C308.5 4.6 296.5 0 288 0s-20.5 4.6-26.7 10.3l-256 226A18.3 18.3 0 0 0 0 248.2a18.6 18.6 0 0 0 4.1 10.7L25.5 282.7a21.1 21.1 0 0 0 12 5.3 21.7 21.7 0 0 0 10.7-4.1l15.9-14V480a32 32 0 0 0 32 32H480a32 32 0 0 0 32-32V269.9l15.9 14A21.9 21.9 0 0 0 538.6 288a20.9 20.9 0 0 0 11.9-5.3l21.4-23.8A21.6 21.6 0 0 0 576 248.2 21 21 0 0 0 570.7 236.3z"/>
    </svg>
    <span v-if="number !== null && number > 0" class="time-text p-2" :class="{'time-text-nonviable': !title}">{{ number }}</span>
    <i v-else-if="loading" class="time-text time-text-nonnumber fas fa-cog fa-spin"></i>
    <span v-else class="time-text time-text-nonnumber p-2" @click.stop="onClickQuestionMark">?</span>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, computed, defineEmits, PropType } from 'vue';
const emit = defineEmits(['questionmarkclicked']);

// Defining props using defineProps with type annotations
const props = defineProps({
  title: {type: String as PropType<string>, default: ''},
  rating: {type: Number as PropType<number>, default: 0},
  number: {type: Number as PropType<number>, default: 0},
  loading: {type: Boolean as PropType<boolean>, default: false},
});

const fillColor = computed(() => {
  return props.title  ? '#253746' : '#efefef';
});

const onClickQuestionMark = (event) => {
  if((props.number !== null && props.number > 0) || props.loading){
    return;
  } else {
    emit('questionmarkclicked', event);
  }
}
</script>

<style scoped>
.home-icon {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
}

.home-svg {
  width: 100%;
  height: 100%;
}

.home-border {
  stroke: #303030; /* Background circle color */
}

.time-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #eadb40;
}
.time-text-nonviable {
  color: #313131;
}
.time-text-nonnumber {
  color: #313131;
}
</style>

<style>
.tooltip-bookingtime {
  padding: 3px;
  background: #253746;
  color: #eadb40;
}
.v-popper__popper
.v-popper__wrapper
.v-popper__inner {
	background: #ffffff !important;
  color: #000000 !important;
  border: 1px #b5b5b5 solid !important;
}
.v-popper__popper
.v-popper__wrapper
.v-popper__arrow-outer {
  border-color: #b5b5b5;
}
.v-popper__popper
.v-popper__wrapper
.v-popper__arrow-inner {
  visibility: visible;
  border-color: #ffffff;
}
</style>
