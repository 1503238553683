<template>
  <div class="wrapper">
    <div class="btn-group btn-group-sm" role="group" aria-label="Responsibility">
      <button
        v-for="option in options"
        :key="option.code"
        type="button"
        @click="setResponsibility(option)"
        :class="responsibilitySelectedClassName(option)"
        :title="option.name"
        v-html="option.code" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, defineProps } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import _get from 'lodash/get';
import { Report } from '@/models';
import { ReportsState } from '@/store/reports/types';

const props = defineProps<{
  notePath: string;
}>();

const store = useStore();
const actProperty: any = inject('actProperty');

// Interface for responsibility options
interface IResponsibilityOption {
  code: string;
  name: string;
}

const options: IResponsibilityOption[] = [
  { code: 'TT',   name: 'Tenant' },
  { code: 'STTA', name: 'Subject To Tenancy Agreement' },
  { code: 'CLN',  name: 'Cleaning' },
  { code: 'FWT',  name: 'Fair Wear and Tear' },
  { code: 'CLR',  name: 'Clarify' },
  { code: 'INF',  name: 'Information Only' },
  { code: 'LL',   name: 'Landlord' },
];

const reportsState = computed(() => store.state.reports as ReportsState);

const note = computed(() => _get(reportsState.value.current, props.notePath));
const responsibility = computed({
  get: () => _get(note.value, 'responsibility', []),
  set: (data: string[]) => {
    setReportDeep({
      path: `${props.notePath}.responsibility`,
      data
    });
  }
});

// Methods converted to regular functions
const setReport = async (report: Report) => {
  await store.dispatch('reports/setReport', report);
};

const setReportDeep = async (payload: {path: string, data: any}) => {
  await store.dispatch('reports/setReportDeep', payload);
};

const responsibilitySelectedClassName = (option: IResponsibilityOption) => {
  let className = `btn btn-${option.code.toLowerCase()} `;

  if (responsibility.value === undefined) {
    return className;
  }

  if (Array.isArray(responsibility.value)) {
    className += responsibility.value.includes(option.code.toUpperCase()) ? 'selected' : '';
  } else {
    actProperty.displayError(Error(`The Note Responsibility is not an array: ${JSON.stringify(responsibility.value)}`));
  }

  return className;
};

const setResponsibility = (option: IResponsibilityOption) => {
  // make a copy so we can replace the old array
  const data = (responsibility.value === undefined) ? [] : [...responsibility.value];

  const foundIndex = data.indexOf(option.code);

  if (foundIndex > -1) {
    data.splice(foundIndex, 1);
  } else {
    data.push(option.code);
  }

  responsibility.value = data;
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/sass/bootstrap/_variables.scss';

.wrapper {
  text-align: right;
}

.btn-group {
  padding-bottom: 0.2rem;
}

.btn-tt.selected   { background-color: $red;    color: $white; }
.btn-stta.selected { background-color: $orange; color: $black; }
.btn-cln.selected  { background-color: $yellow; color: $black; }
.btn-fwt.selected  { background-color: $green;  color: $white; }
.btn-clr.selected  { background-color: $info;   color: $white; }
.btn-inf.selected  { background-color: $black;  color: $white; }
.btn-ll.selected   { background-color: $grey;   color: $white; }

</style>
