import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class User extends Base {

  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;

  /**
   * Constructor initialises the variables from the class User
   * @param data - the data to construct a type with all properties of <User>
   */
  public constructor(data?: Partial<User>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.firstName = _get(data, 'firstName', '');
    this.lastName = _get(data, 'lastName', '');
    this.email = _get(data, 'email', '');
  }

}
