<template>
  <div class="card bg-light booking-section" v-if="booking.id">
    <h3 class="card-header booking-section-header">Send confirmations</h3>
    <!--div class="card-body" v-if="isQCuser">
      <div class="row" v-if="!isNewBooking()">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">Confirmation to client</div>
                <div class="col-md-12">
                  <div class="btn-group" role="group" aria-label="Send confirmation email to client">
                    <button class="btn btn-sm btn-outline-secondary" @click="showClientConfirmationModal"
                      :title="clientemailbuttontooltip" disabled>
                      <img class="trafficlight" src="~@/assets/images/traffic-red.png"
                        v-if="!emaillogs.clientconfirmationsentdate" />
                      <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else />
                      Email
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" @click="showClientEmailLogsModal"
                      v-if="emaillogs.clientemaillogs.length">
                      <i class="fa fa-history"></i>
                    </button>
                    <ProgressButton @dblclick="generateClientConfirmationPdf(booking, $event)"
                      class="btn btn-outline-primary" title="Confirmation">
                      <i class="far fa-file-pdf"></i> Confirmation
                    </ProgressButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">Correspondance/Feedback to client</div>
                <div class="col-md-12">
                  <div class="btn-group" role="group" aria-label="Send corresponance/feedback email to client">
                    <button class="btn btn-sm btn-outline-secondary" @click="showFeedbackEmailModal"
                      style="min-height: 33.2px; min-width: 66.8px" disabled>
                      <img class="trafficlight" src="~@/assets/images/traffic-red.png"
                       v-if="!emaillogs.feedbacksentddate" />
                      <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else />
                      Email 
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" @click="showFeedbackEmailLogsModal"
                      v-if="emaillogs.feedbackemaillogs.length" style="min-height: 33.2px;">
                      <i class="fa fa-history"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2" v-if="booking.managedtype === 'Non-managed'">
          <div class="row">
            <div class="col-md-12">Confirmation to landlord</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group" role="group" aria-label="Send email to landlord">
                <button class="btn btn-sm btn-outline-secondary" @click="showLandlordConfirmationModal"
                  :title="landlordemailbuttontooltip" style="min-height: 33.2px;" disabled>
                  <img class="trafficlight" src="~@/assets/images/traffic-red.png"
                    v-if="!emaillogs.landlordconfirmationsentdate" />
                  <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else />
                  Email
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="showLandlordEmailLogsModal"
                  v-if="emaillogs.landlordemaillogs.length" style="min-height: 33.2px;">
                  <i class="fa fa-history"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{ 'col-md-2': booking.managedtype === 'Non-managed', 'col-md-3': booking.managedtype != 'Non-managed' }">
          <div class="row">
            <div class="col-md-12">Confirmation to tenant</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group" role="group" aria-label="Send email to tenant">
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantConfirmationModal"
                  :title="tenantemailbuttontooltip" disabled style="min-height: 33.2px;">
                  <img v-if="emaillogs.tenantconfirmationreceiveddate" class="trafficlight"
                    src="~@/assets/images/traffic-green.png" />
                  <img v-else-if="!emaillogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-red.png" />
                  <img v-else-if="emaillogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-amber.png" />
                  Email
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantEmailLogsModal"
                  v-if="emaillogs.tenantemaillogs.length" style="min-height: 33.2px;">
                  <i class="fa fa-history"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{ 'col-md-2': booking.managedtype === 'Non-managed', 'col-md-3': booking.managedtype != 'Non-managed' }">
          <div class="row">
            <div class="col-md-12">Send SMS to tenant</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group" role="group" aria-label="Send SMS">
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantSMSModal"
                  :title="tenantsmsbuttontooltip" disabled style="min-height: 33.2px;">
                  <img v-if="smslogs.tenantconfirmationreceiveddate" class="trafficlight"
                    src="~@/assets/images/traffic-green.png" />
                  <img v-else-if="!smslogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-red.png" />
                  <img v-else-if="smslogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-amber.png" />
                  SMS
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantSMSLogsModal"
                  v-if="smslogs.logs.length" style="min-height: 33.2px;">
                  <i class="fa fa-history"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </!--div-->
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">Confirmation to client</div>
                <div class="col-md-12">
                  <div class="btn-group" role="group" aria-label="Send confirmation email to client">
                    <button class="btn btn-sm btn-outline-secondary" @click="showClientConfirmationModal"
                      :title="clientemailbuttontooltip">
                      <img class="trafficlight" src="~@/assets/images/traffic-red.png"
                        v-if="!emaillogs.clientconfirmationsentdate" />
                      <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else />
                      Email
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" @click="showClientEmailLogsModal"
                      v-if="emaillogs.clientemaillogs.length">
                      <i class="fa fa-history"></i>
                    </button>
                    <ProgressButton @dblclick="generateClientConfirmationPdf(booking, $event)"
                      class="btn btn-outline-primary" title="Confirmation">
                      <i class="far fa-file-pdf"></i> Confirmation
                    </ProgressButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">Correspondance/Feedback to client</div>
                <div class="col-md-12">
                  <div class="btn-group" role="group" aria-label="Send corresponance/feedback email to client">
                    <button class="btn btn-sm btn-outline-secondary" @click="showFeedbackEmailModal"
                      style="min-height: 33.2px; min-width: 66.8px">
                      <img class="trafficlight" src="~@/assets/images/traffic-red.png"
                       v-if="!emaillogs.feedbacksentddate" />
                      <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else />
                      Email
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" @click="showFeedbackEmailLogsModal"
                      v-if="emaillogs.feedbackemaillogs.length" style="min-height: 33.2px;">
                      <i class="fa fa-history"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2" v-if="booking.managedtype === 'Non-managed'">
          <div class="row">
            <div class="col-md-12">Confirmation to landlord</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group" role="group" aria-label="Send email to landlord">
                <button class="btn btn-sm btn-outline-secondary" @click="showLandlordConfirmationModal"
                  :title="landlordemailbuttontooltip" style="min-height: 33.2px;">
                  <img class="trafficlight" src="~@/assets/images/traffic-red.png"
                    v-if="!emaillogs.landlordconfirmationsentdate" />
                  <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else />
                  Email
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="showLandlordEmailLogsModal"
                  v-if="emaillogs.landlordemaillogs.length" style="min-height: 33.2px;">
                  <i class="fa fa-history"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{ 'col-md-2': booking.managedtype === 'Non-managed', 'col-md-3': booking.managedtype != 'Non-managed' }">
          <div class="row">
            <div class="col-md-12">Confirmation to tenant</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group" role="group" aria-label="Send email to tenant">
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantConfirmationModal"
                  :title="tenantemailbuttontooltip" :disabled="booking.donotcontacttt" style="min-height: 33.2px;">
                  <img v-if="emaillogs.tenantconfirmationreceiveddate" class="trafficlight"
                    src="~@/assets/images/traffic-green.png" />
                  <img v-else-if="!emaillogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-red.png" />
                  <img v-else-if="emaillogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-amber.png" />
                  Email
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantEmailLogsModal"
                  v-if="emaillogs.tenantemaillogs.length" style="min-height: 33.2px;">
                  <i class="fa fa-history"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{ 'col-md-2': booking.managedtype === 'Non-managed', 'col-md-3': booking.managedtype != 'Non-managed' }">
          <div class="row">
            <div class="col-md-12">Send SMS to tenant</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group" role="group" aria-label="Send SMS">
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantSMSModal"
                  :title="tenantsmsbuttontooltip" :disabled="booking.donotcontacttt" style="min-height: 33.2px;">
                  <img v-if="smslogs.tenantconfirmationreceiveddate" class="trafficlight"
                    src="~@/assets/images/traffic-green.png" />
                  <img v-else-if="!smslogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-red.png" />
                  <img v-else-if="smslogs.tenantconfirmationsentdate" class="trafficlight"
                    src="~@/assets/images/traffic-amber.png" />
                  SMS
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="showTenantSMSLogsModal"
                  v-if="smslogs.logs.length" style="min-height: 33.2px;">
                  <i class="fa fa-history"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <EmailModal :id="`clientconfirmation-email-modal`" :name="`clientconfirmation-email-modal`"
      ref="clientconfirmationEmailModal" :title="clientconfirmationtitle" :reporttype="reporttype" :target="target"
      :chronorder="'Initial confirmation'" :customer="booking.customer.id" :fromaddress="fromaddress"
      :toaddresses="clienttoaddresses" label="Confirmation to client" />

    <EmailModal :id="`clientfeedback-email-modal`" :name="`clientfeedback-email-modal`" ref="clientfeedbackEmailModal"
      title="Correspondance/Feedback to client" :reporttype="reporttype" :target="target"
      chronorder="Correspondance/Feedback to Client" :customer="booking.customer.id" :fromaddress="fromaddress"
      :toaddresses="bookedbyaddresses" label="Correspondance/Feedback to client" />

    <EmailModal :id="`landlordconfirmation-email-modal`" :name="`landlordconfirmation-email-modal`"
      ref="landlordconfirmationEmailModal" :title="landlordconfirmationemailtitle" :reporttype="reporttype"
      :target="'Landlord'" :chronorder="'Initial confirmation'" :customer="booking.customer.id"
      :fromaddress="fromaddress" :toaddresses="landlordtoaddresses"  label="Confirmation to landlord"/>

    <EmailModal :id="`tenantconfirmation-email-modal`" :name="`tenantconfirmation-email-modal`"
      ref="tenantconfirmationEmailModal" :title="tenantconfirmationemailtitle" :reporttype="reporttype"
      :target="'Tenant'" :chronorder="'Initial confirmation'" :customer="booking.customer.id" :fromaddress="fromaddress"
      :toaddresses="tenanttoaddresses"  label="Confirmation to tenant"/>

    <SMSModal :id="`tenantconfirmation-sms-modal`" :name="`tenantconfirmation-sms-modal`"
      ref="tenantconfirmationSMSModal" :title="tenantconfirmationsmstitle" :reporttype="reporttype" :target="'Tenant'"
      :chronorder="'Initial confirmation'" :customer="booking.customer.id" />

    <EmailLogsModal :name="`clientconfirmation-emaillogs-modal`" ref="clientconfirmationEmailLogsModal"
      :target="'Client'" :title="clientconfirmationemaillogstitle" />

    <EmailLogsModal :name="`clientfeedback-emaillogs-modal`" ref="clientfeedbackEmailLogsModal" :target="target"
      chronorder="Correspondance/Feedback to Client" title="Correspondance/Feedback email logs" />

    <EmailLogsModal :name="`landlordconfirmation-emaillogs-modal`" ref="landlordconfirmationEmailLogsModal"
      :target="'Landlord'" title="Email Logs" />

    <EmailLogsModal :name="`tenantconfirmation-emaillogs-modal`" ref="tenantconfirmationEmailLogsModal"
      :target="'Tenant'" title="Email Logs" />

    <SMSLogsModal :name="`tenantconfirmation-smslogs-modal`" ref="tenantconfirmationSMSLogsModal" title="SMS Logs" />

    <AlertDialog ref="alertDialog" name="cnalertdialog" />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from "uuid";
import { EmailLogs, EmailLog, SMSLogs, Booking } from "@/models";
import ProgressButton from "@/components/ProgressButton.vue";
import AlertDialog from "@/components/modals/AlertDialog.vue";
import { useToast } from "vue-toastification"; // Updated import as per guideline 12

import EmailModal from "@/components/modals/EmailModal.vue";
import EmailLogsModal from "@/components/modals/EmailLogsModal.vue";
import SMSModal from "@/components/modals/SMSModal.vue";
import SMSLogsModal from "@/components/modals/SMSLogsModal.vue";
import { useRoute } from 'vue-router';

const actProperty: any = inject('actProperty');
const toasted = useToast(); // Using useToast as per guideline 12

const clientconfirmationEmailModal = ref(null);
const clientfeedbackEmailModal = ref(null);
const landlordconfirmationEmailModal = ref(null);
const tenantconfirmationEmailModal = ref(null);
const tenantconfirmationSMSModal = ref(null);
const clientconfirmationEmailLogsModal = ref(null);
const clientfeedbackEmailLogsModal = ref(null);
const landlordconfirmationEmailLogsModal = ref(null);
const tenantconfirmationEmailLogsModal = ref(null);
const tenantconfirmationSMSLogsModal = ref(null);
const alertDialog = ref(null);
const route = useRoute();

const store = useStore();
const emaillogs = computed(() => store.getters['diary/emaillogs']);
const smslogs = computed(() => store.getters['diary/smslogs']);
const booking = computed(() => store.getters['diary/booking']);
const systemproperties = computed(() => store.getters['dictionary/systemproperties']);
const isQCuser = computed(() => store.getters['auth/isQCUser']);
const isBookingManagement = computed(() => store.getters['auth/isBookingManagement']);
const setCurrentEmailLog = (log: EmailLog): void => {
  store.commit('diary/setCurrentEmailLog', log);
};
const removeRequest = (id: string): void => {
  store.commit('app/removeRequest', id);
};
const getEmailLogs = (bookingid: string): Promise<EmailLogs> => {
  return store.dispatch('diary/getEmailLogs', bookingid);
};
const getSMSLogs = (bookingid: string): Promise<SMSLogs> => {
  return store.dispatch('diary/getSMSLogs', bookingid);
};
const generateConfirmationNotePdfAction = (booking: Booking): Promise<any> => {
  return store.dispatch('diary/generateConfirmationNotePdf', booking);
};

const clientconfirmationtitle = computed(() => {
  let title = "Confirmation email";
  if (booking.value && booking.value.customer) {
    if (booking.value.customer.companyName)
      title = "Confirmation email to " + booking.value.customer.companyName;
    if (booking.value.customer.branchName)
      title = title + " - " + booking.value.customer.branchName;
  }
  return title;
});

const landlordconfirmationemailtitle = computed(() => {
  let title = "Confirmation email";
  if (booking.value && booking.value.landlords && booking.value.landlords.length) {
    let landlordnames = booking.value.landlords
      .filter(l => l.llemail)
      .map(l => l.llname);
    if (landlordnames.length)
      title = "Confirmation email to " + landlordnames.join(", ");
  }
  return title;
});

const tenantconfirmationemailtitle = computed(() => {
  let title = "Confirmation email";
  if (booking.value && booking.value.tenants && booking.value.tenants.length) {
    let tenantnames = booking.value.tenants
      .filter(t => t.ttemail)
      .map(t => t.ttname);
    if (tenantnames.length)
      title = "Confirmation email to " + tenantnames.join(", ");
  }
  return title;
});

const tenantconfirmationsmstitle = computed(() => {
  let title = "Confirmation sms";
  if (booking.value && booking.value.tenants && booking.value.tenants.length) {
    let tenantnames = booking.value.tenants
      .filter(t => t.ttmobile)
      .map(t => t.ttname);
    if (tenantnames.length)
      title = "Confirmation sms to " + tenantnames.join(", ");
  }
  return title;
});

const clientconfirmationemaillogstitle = computed(() => {
  let title = "Email logs";
  if (booking.value && booking.value.customer && booking.value.customer.companyName) {
    title = "Email logs to " + booking.value.customer.companyName;
  }
  return title;
});


const reporttype = computed(() => booking.value.jobtype);

const target = computed(() =>
  booking.value.managedtype === 'Managed' ? "Managed Client" : "Non-Managed Client"
);

const showClientConfirmationModal = () => {
  const apptime = booking.value.appointmenttimeForDisplay;
  if (!isBookingManagement.value && !isQCuser.value) {
    if (
      apptime === "No Tenant but Fixed Time" ||
      apptime === "Flexi all day" ||
      apptime === "Flexi AM" ||
      apptime === "Flexi PM"
    ) {
      const modal = alertDialog.value as any;
      if (modal) {
        let message = "";
        if (apptime === "No Tenant but Fixed Time")
          message =
            "Logistics team, you must remove the tenant attending line on the email template";
        else
          message =
            "This booking is flexible! Logistics team, you must remove the 4th bullet point (timing) and the tenant attending line on the email template";
        modal.init("Warning", message, "Yes, I understand");
        modal.show();
      }
    }
  }

  const modal = clientconfirmationEmailModal.value as any;
  modal.init();
  modal.show();
}

const showFeedbackEmailModal = () => {
  const modal = clientfeedbackEmailModal.value as any;
  modal.init();
  modal.show();
}

const showLandlordConfirmationModal = () => {
  const modal = landlordconfirmationEmailModal.value as any;
  modal.init();

  modal.show();
}

const showTenantConfirmationModal = () => {
  const modal = tenantconfirmationEmailModal.value as any;
  modal.init();

  modal.show();
}

const showTenantSMSModal = () => {
  const modal = tenantconfirmationSMSModal.value as any;
  modal.init();

  modal.show();
}

const showClientEmailLogsModal = () => {
  getEmailLogs(booking.value.id);
  if (
    emaillogs.value &&
    emaillogs.value.clientemaillogs &&
    emaillogs.value.clientemaillogs.length > 0
  )
    setCurrentEmailLog(emaillogs.value.clientemaillogs[0]);
  else setCurrentEmailLog(new EmailLog());
  const modal = clientconfirmationEmailLogsModal.value as any;
  modal.show();
}

const showFeedbackEmailLogsModal = () => {
  const modal = clientfeedbackEmailLogsModal.value as any;
  modal.init();

  getEmailLogs(booking.value.id);
  if (
    emaillogs.value &&
    emaillogs.value.feedbackemaillogs &&
    emaillogs.value.feedbackemaillogs.length > 0
  )
    setCurrentEmailLog(emaillogs.value.feedbackemaillogs[0]);
  else setCurrentEmailLog(new EmailLog());
  modal.show();
}

const showLandlordEmailLogsModal = () => {
  const modal = landlordconfirmationEmailLogsModal.value as any;
  getEmailLogs(booking.value.id);
  if (
    emaillogs.value &&
    emaillogs.value.landlordemaillogs &&
    emaillogs.value.landlordemaillogs.length > 0
  )
    setCurrentEmailLog(emaillogs.value.landlordemaillogs[0]);
  else setCurrentEmailLog(new EmailLog());
  modal.show();
}

const showTenantEmailLogsModal = () => {
  const modal = tenantconfirmationEmailLogsModal.value as any;
  getEmailLogs(booking.value.id);
  if (
    emaillogs.value &&
    emaillogs.value.tenantemaillogs &&
    emaillogs.value.tenantemaillogs.length > 0
  )
    setCurrentEmailLog(emaillogs.value.tenantemaillogs[0]);
  else setCurrentEmailLog(new EmailLog());
  modal.show();
}

const showTenantSMSLogsModal = () => {
  const modal = tenantconfirmationSMSLogsModal.value as any;
  getSMSLogs(booking.value.id);
  modal.show();
}

const clienttoaddresses = computed(() => {
  return [booking.value.bookedbyemail];
});

const landlordtoaddresses = computed(() => {
  let toaddresses = [];
  if (booking.value && booking.value.landlords && booking.value.landlords.length) {
    toaddresses = booking.value.landlords
      .filter(l => l.llemail)
      .map(l => l.llemail);
  }
  return [...toaddresses];
});

const tenanttoaddresses = computed(() => {
  let toaddresses = [];
  if (booking.value && booking.value.tenants && booking.value.tenants.length) {
    toaddresses = booking.value.tenants
      .filter(t => t.ttemail)
      .map(t => t.ttemail);
  }
  return [...toaddresses];
});

const bookedbyaddresses = computed(() => {
  let toaddresses = [];
  if (booking.value && booking.value.bookedbyarray && booking.value.bookedbyarray.length && booking.value.customer.policies.onsitefeedbackto.bookedby) {
    toaddresses = booking.value.bookedbyarray
      .filter(b => b.bbemail)
      .map(b => b.bbemail);
  }
  return [...toaddresses];
});

const fromaddress = computed(() => {
  let prop = systemproperties.value?.find(sp => sp.propertykey === 'booking.email.fromaddress');
  return prop?.value;
});
const clientemailbuttontooltip = computed(() => {
  if (!emaillogs.value.clientconfirmationsentdate) {
    return "";
  } else {
    return `Last email was sent on ${actProperty.formatDatetimeForDisplay(
      emaillogs.value.clientconfirmationsentdate
    )}`;
  }
});

const landlordemailbuttontooltip = computed(() => {
  if (!emaillogs.value.landlordconfirmationsentdate) {
    return "";
  } else {
    return `Last email was sent on ${actProperty.formatDatetimeForDisplay(
      emaillogs.value.landlordconfirmationsentdate
    )}`;
  }
});

const tenantemailbuttontooltip = computed(() => {
  if (!emaillogs.value.tenantconfirmationsentdate) {
    return "";
  } else {
    if (!emaillogs.value.tenantconfirmationreceiveddate) {
      return `Last email was sent on ${actProperty.formatDatetimeForDisplay(
        emaillogs.value.tenantconfirmationsentdate
      )}`;
    } else {
      if (emaillogs.value.tenantconfirmationresponse.toLowerCase() === "yes") {
        return `Tenant confirmed on ${actProperty.formatDatetimeForDisplay(
          emaillogs.value.tenantconfirmationreceiveddate
        )}`;
      } else if (emaillogs.value.tenantconfirmationresponse.toLowerCase() === "no") {
        return `Tenant declined on ${actProperty.formatDatetimeForDisplay(
          emaillogs.value.tenantconfirmationreceiveddate
        )}`;
      } else if (emaillogs.value.tenantconfirmationresponse.toLowerCase() === "callback") {
        return `Tenant request callback on ${actProperty.formatDatetimeForDisplay(
          emaillogs.value.tenantconfirmationreceiveddate
        )}`;
      }
      else {
        return "";
      }
    }
  }
});

const tenantsmsbuttontooltip = computed(() => {
  if (!smslogs.value.tenantconfirmationsentdate) {
    return "";
  } else {
    if (!smslogs.value.tenantconfirmationreceiveddate) {
      return `Last sms was sent on ${actProperty.formatDatetimeForDisplay(
        emaillogs.value.tenantconfirmationsentdate
      )}`;
    } else {
      if (smslogs.value.tenantconfirmationresponse.toLowerCase() === "yes") {
        return `Tenant confirmed on ${actProperty.formatDatetimeForDisplay(
          smslogs.value.tenantconfirmationreceiveddate
        )}`;
      } else if (smslogs.value.tenantconfirmationresponse.toLowerCase() === "no") {
        return `Tenant declined on ${actProperty.formatDatetimeForDisplay(
          smslogs.value.tenantconfirmationreceiveddate
        )}`;
      }
      else {
        return "";
      }
    }
  }
});

/**
 * Download Client Confirmation PDF
 *
 * @param {Booking} booking
 * @param {String} requestId
 */
const generateClientConfirmationPdf = (
  booking: Booking,
  requestId: string
): void => {
  generateConfirmationNotePdfAction(booking)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Client confirmation PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}
const isNewBooking = () => {
  return route.params.id == "new";
}


</script>


<style scoped lang="scss">
.trafficlight {
  height: 20px;
  width: auto;
}
</style>