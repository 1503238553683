import _omit from 'lodash/omit';
import _get from 'lodash/get';

import { Base, Contact } from '@/models';
import { toCamelCase,datetimeToUTC } from '@/utilities';

export class PropertyDetails extends Base {

  public furnished: string;
  public generalNotes: string;
  public tenancystartdate: string;
  public qcWarningNotes: string;
  public qcCriticalNotes: string;
  public locationPhotos: string[];
  public cleaningReceiptPhotos: string[];
  public parkingLocation: string;
  public parkingSpaceNo: string;
  public stopcockLocation: string;
  public forwardingContact: Contact;
  public historicalCleaningStatus: string;


  /**
   * Constructor initialises the variables from the class PropertyDetails
   * @param data - the data to construct a type with all properties of <PropertyDetails>
   */
  public constructor(data?: Partial<PropertyDetails>) {
    super(data);
    data = toCamelCase(data);

    this.furnished = _get(data, 'furnished', '');
    this.generalNotes = _get(data, 'generalNotes', '');
    this.tenancystartdate = _get(data, 'tenancystartdate', '');
    this.qcWarningNotes = _get(data, 'qcWarningNotes', '');
    this.qcCriticalNotes = _get(data, 'qcCriticalNotes', '');
    this.locationPhotos = _get(data, 'locationPhotos', []);
    this.cleaningReceiptPhotos = _get(data, 'cleaningReceiptPhotos', []);
    this.parkingLocation = _get(data, 'parkingLocation', '');
    this.parkingSpaceNo = _get(data, 'parkingSpaceNo', '');
    this.stopcockLocation = _get(data, 'stopcockLocation', '');
    this.forwardingContact = new Contact(_get(data, 'forwardingContact'));
    this.historicalCleaningStatus = _get(data, 'historicalCleaningStatus', '');
  }

}
