<template>
  <div class="customer">
    <h1 v-if="isNewUser()">Add New User</h1>
    <h1 v-else>Edit User</h1>

    <div class="user-header">
      <form>
        <fieldset>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Office</label>
            <div class="col-sm-4">
              <singleselect-text
                :value="findUserACTOffice(user)"
                :options="officeNameOptions"
                @select="onSelectOffice"
              ></singleselect-text>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Company</label>
            <div class="col-sm-3">
              <multiselect-text
                v-model="companyName"
                :value="companyName"
                :options="companyNameOptions"
                :allow-empty="true"
                :taggable="true"
                @tag="onTagCompanyName"
                @select="onSelectCompanyName"
              >
                <template #clear
                    v-if="companyName.length">
                  <i @mousedown.prevent.stop="onTagCompanyName('')"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Company Name"
                  ></i>
                </template>
              </multiselect-text>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Username</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                :value="user.Username"
                disabled
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Email</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                :value="user.findAttributeValue('email')"
                disabled
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Confirmation status</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                :value="user.UserStatus"
                disabled
              />
            </div>
            <div class="col-sm-2">
              <button
                v-if="passwordResetAllowed()"
                class="btn btn-primary"
                @click="passwordReset()"
              >
                Reset Password
              </button>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Status</label>
            <div class="col-sm-4" style="display: table">
              <div
                v-if="user.Enabled"
                class="status-enabled chip"
                @click.prevent="disable()"
              >
                <i class="fas fa-check-circle"></i> Enabled
              </div>
              <div
                v-else
                class="status-disabled chip"
                @click.prevent="enable()"
              >
                <i class="fas fa-minus-circle"></i> Disabled
              </div>
            </div>
          </div>

          <div class="form-group row" style="margin-top: 20px">
            <label class="col-sm-3 col-form-label">Groups</label>
            <div class="col-sm-8">
              <div
                v-for="group in groups"
                :key="group.GroupName"
                class="row usergroup"
                style="margin-left: 0px"
              >
                <div
                  v-if="isUserGroup(group)"
                  class="col-sm-2 chip status-enabled"
                  @click.prevent="unassignGroup(group)"
                >
                  <i class="fas fa-check-circle"></i> {{ group.GroupName }}
                </div>
                <div
                  v-else
                  class="col-sm-2 chip status-disabled"
                  @click.prevent="assignGroup(group)"
                >
                  <i class="fas fa-minus-circle"></i> {{ group.GroupName }}
                </div>
                <div class="col-sm-6">
                  {{ group.Description }}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>

    <!-- Bottom navbar -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary"></nav>
  </div>
</template>

<script lang="ts" setup>
import { CognitoUser, CognitoGroup, Office } from '@/models';
import router from '@/router';
import { computed, onMounted, ref, defineProps } from 'vue';
import { useStore } from 'vuex';

// Define props with their types
const props = defineProps<{
  // You should add props types here based on your needs, the example doesn't include prop definitions
}>();

const store = useStore();
const user = ref<CognitoUser>(new CognitoUser);
const officeNameOptions = ref<string[]>([]);
const companyNameOptions = ref<string[]>([]);
const selectedCompanyName = ref<string>('');
const companySlugMap = ref<Map<string, string>>(new Map());
const groups = computed(() => store.getters['sysadmin/groups'] as CognitoGroup[]);
const offices = computed(() => store.getters['offices/getOffices'] as Office[]);

const companyName = computed({
  get: () => selectedCompanyName.value, // Getter
  set: (newValue) => {
    selectedCompanyName.value = newValue; // Setter
  }
});

const changeAttribute = async (payload: {
    username: string;
    attributename: string;
    attributevalue: string;
  }) => {
  await store.dispatch('sysadmin/changeAttribute', payload);
};

const assignUserGroup = async (payload: {
    username?: string;
    groupname?: string;
  }) => {
  await store.dispatch('sysadmin/assignUserGroup', payload);
};

const unassignUserGroup = async (payload: {
    username?: string;
    groupname?: string;
  }) => {
  await store.dispatch('sysadmin/unassignUserGroup', payload);
};

const resetPassword = async (username: string) => await store.dispatch('sysadmin/resetPassword', username);

const disableUser = async (payload: {
    username: string;
    disable: boolean;
  }) => {
  await store.dispatch('sysadmin/disableUser', payload);
};

const isNewUser = () => {
  return router.currentRoute.value.params.sub === "new";
};

const passwordResetAllowed = () => {
  return user.value?.UserStatus !== "FORCE_CHANGE_PASSWORD";
};

const findUserACTOffice = (user: CognitoUser) => {
  const office = store.getters['offices/getOffices'].find(
    (o: Office) => o.id === user.findAttributeValue("custom:office_id")
  );
  return office?.name;
};

// Conversion of methods to regular anonymous functions
const onSelectCompanyName = async (companyName: string) => {
  if (companyName) {
      selectedCompanyName.value = companyName;
      changeAttribute({
        username: user.value!.Username,
        attributename: "custom:company_name",
        attributevalue: selectedCompanyName.value,
      });
      let company_slug: string | undefined = companySlugMap.value.get(
        selectedCompanyName.value
      );
      if (company_slug) {
        changeAttribute({
          username: user.value!.Username,
          attributename: "custom:company_slug",
          attributevalue: company_slug,
        });
      }
    }
};

const onTagCompanyName = async (val: string) => {
  selectedCompanyName.value = val;
    if (!selectedCompanyName.value) {
      changeAttribute({
        username: user.value!.Username,
        attributename: "custom:company_name",
        attributevalue: "",
      });
      changeAttribute({
        username: user.value!.Username,
        attributename: "custom:company_slug",
        attributevalue: "",
      });
    }
};

const onSelectOffice = async (officename: string) => {
  let office: Office | undefined = offices.value.find(
      (office) => office.name === officename
    );
    if (office) {
      changeAttribute({
        username: user.value!.Username,
        attributename: "custom:office_id",
        attributevalue: office.id,
      });
    }
};

const passwordReset = () => {
  resetPassword(user.value!.Username);
};

const disable = () => {
  disableUser({ username: user.value!.Username, disable: true });
};

const enable = () => {
  disableUser({ username: user.value!.Username, disable: false });
};

const isUserGroup = (group: CognitoGroup) => {
  if (user.value!.CognitoUserGroups) {
    let userGroup: CognitoGroup | undefined =
      user.value!.CognitoUserGroups.find(
      (g: CognitoGroup) => g.GroupName == group.GroupName
    );
    return userGroup;
  }
};

const assignGroup = (group: CognitoGroup) => {
  assignUserGroup({
    username: user.value!.Username,
    groupname: group.GroupName,
  });
};

const unassignGroup = (group: CognitoGroup) => {
  unassignUserGroup({
    username: user.value!.Username,
    groupname: group.GroupName,
  });
};

// Lifecycle hooks
onMounted(async () => {
  officeNameOptions.value = store.getters['offices/getOffices'].map(
    (office: Office) => office.name
  );
  // Fetch user details and any other initializations
  user.value = await store.dispatch('sysadmin/getUser', router.currentRoute.value.params.sub);
  
  // Replace the Promise.all with individual await calls as needed
  // ... implement mounted logic
});

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.status-enabled {
  display: table-cell;
  vertical-align: middle;
  color: green;
  background-color: rgb(209, 241, 209) !important;
}
.status-disabled {
  display: table-cell;
  vertical-align: middle;
  color: gray;
}

.chip {
  display: inline-block;
  padding: 0 10px;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  border-radius: 20px;
  background-color: #f1f1f1;
  margin: 2px 0px 0px 0px;
}

.usergroup {
  margin-bottom: 15px;
}

.usergroup-check {
  font-size: 1.2rem;
}
</style>

        
        